<template>
  <div class="login">
    <img class="logo" src="@/assets/img/logo.png" alt="" />
    <form class="login-body" @submit.prevent="onSubmit">
      <div class="password-login">Selamat Datang di BRD</div>
      <div class="login_title">Login</div>
      <div class="input-block">
        <div>Nomor Hp</div>
        <div class="input_box">
          <input
            v-model.trim="phone"
            type="tel"
            autofocus
            placeholder="Silakan masukkan nomor HP denganawalan 08"
            maxlength="13"
          />
        </div>
      </div>
      <div class="input-block">
        <div>Password</div>
        <div class="input_box">
          <input
            v-model.trim="password"
            maxlength="13"
            type="text"
            class="password"
            placeholder="Kombinasi angka dan huruf tanpa karakter spesial8-13 digit"
          />
        </div>
      </div>
      <div class="forget-password">
        <span @click="forgetPassword"> Lupa Password? Tidak dapat login!</span>
      </div>
      <div class="login-btn" @click="onSubmit">
        <button type="button">Login</button>
        <img src="@/assets/img/login_btn_yinying.png" alt="" />
      </div>
      <!-- <button type="button" class="SMS-btn" @click="goMessageLogin">
        Masuk dengan SMS
      </button> -->
      <button type="button" class="Daftar-btn" @click="goRegister">
        Tidak ada akun? Segera daftar!
      </button>
    </form>
  </div>
</template>

<script>
import { Toast } from 'vant'
import request from '@/utils/request'
import { validatePhoneNumber, validatePassword } from '@/utils/validator'
import { isIOS } from '@/utils/detect'
export default {
  name: 'Login',
  data () {
    return {
      phone: '',
      password: '',
      isIOS
    }
  },
  methods: {
    forgetPassword () {
      console.log(1231)

      const { query } = this.$route
      this.$router.push({
        path: '/forgetPassword',
        query
      })
    },
    // 注册
    goRegister () {
      const { query } = this.$route
      this.$router.push({
        path: '/register',
        query
      })
    },
    validate () {
      const regex = /^(08|628)/
      if (!regex.test(this.phone)) {
        Toast('Masukkan nomor handphone dengan awalan 08')
        return false
      }
      if (!this.phone) {
        Toast('Masukkan nomor handphone dengan awalan 08')
        return false
      }
      if (!validatePhoneNumber(this.phone)) {
        Toast('Nomor handphone salah')
        return false
      }
      if (!this.password) {
        Toast('Masukkan Kombinasi angka dan huruf tanpa karakter spesial8-13 digit')
        return false
      }
      if (!validatePassword(this.password)) {
        Toast('Masukkan Kombinasi angka dan huruf tanpa karakter spesial8-13 digit')
        return false
      }
      return true
    },
    onSubmit () {
      if (!this.validate()) return
      if (this.flag) return
      this.flag = true
      const postData = {
        lodrginType: 1,
        usdrerPhone: this.phone,
        usdrerPassword: this.password
      }
      request.post('usdrerLogin', postData)
        .then(res => {
          this.$store.commit('recordInfo', {
            type: 'userInfo',
            value: res
          })
          localStorage.setItem('userInfo', JSON.stringify(res))
          this.addressBook()
          this.$router.push('/home')
        })
        .finally(() => {
          this.flag = false
        })
    },
    goMessageLogin () {
      const { query } = this.$route
      this.$router.push({
        path: '/messageLogin',
        query
      })
    }

  }
}
</script>

<style scoped lang="less">
.login {
  padding: 0 34px;
  padding-bottom: 100px;
}
.logo {
  display: block;
  margin: 20px auto;
  margin-bottom: 0;
  width: 120px;
}
.header-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 73px 30px 0;

  p {
    font-weight: 600;

    &:first-child {
      font-size: 40px;
      color: #4837bc;
    }

    &:last-child {
      font-size: 32px;
      color: #f9f9f9;
    }
  }
}

.title-block {
  display: flex;
  height: 88px;
  line-height: 88px;

  & > div {
    flex: 1;
    text-align: center;
  }
}

.password-login {
  font-size: 34px;
  font-weight: 600;
  margin-top: 50px;
  margin-bottom: 100px;
  text-align: center;
}
.login_title {
  font-weight: 600;
  font-size: 38px;
}
.message-login {
  border-radius: 10px;
  font-size: 28px;
  font-weight: 900;
  color: #fff;
  background-color: #ffc529;
}

.input-block {
  div {
    color: #979696;
    font-size: 24px;
    margin-bottom: 14px;
  }

  &:nth-child(n + 2) {
    margin-top: 50px;
  }
  .input_box {
    box-shadow: 0px 14px 37px 10px rgba(154, 182, 252, 0.28);
    border-radius: 20px;
  }

  input {
    display: block;
    width: 100%;
    height: 88px;
    line-height: 88px;
    padding-left: 20px;
    border: none;
    box-sizing: border-box;
    font-size: 30px;
    background: #ffffff;
    // box-shadow: 0px 14px 37px 10px rgba(154, 182, 252, 0.28);
    border-radius: 20px;
    color: #333;
    &::placeholder {
      font-size: 23px;
    }

    &:-webkit-autofill {
      transition: background-color 5000s ease-in-out 0s;
    }
  }
}

.password {
  -webkit-text-security: disc;
}

.forget-password {
  display: flex;
  justify-content: end;
  margin-bottom: 66px;
  margin-top: 30px;
  font-weight: 500;
  font-size: 26px;
  color: #5f76f3;
}
.login-btn {
  position: relative;
  font-size: 24px;
  button {
    .submit-btn;
    background: url("~@/assets/img/login_btn.png");
    background-size: 100% 100%;
    font-weight: 600;
    font-size: 34px;
  }
  img {
    position: absolute;
    bottom: -90px;
    height: 180px;
    left: 50%;
    transform: translateX(-50%);
    width: 600px;
    opacity: 0.8;
  }
}
.SMS-btn {
  .cancel-btn;
  margin-top: 29px;
}

.footer {
  width: 358px;
  height: 85px;
  margin: 30px auto 25px;
}
.Daftar-btn {
  .cancel-btn;
  background: none;
  margin-top: 120px;
  color: #5f7bf0;
  border: 1px solid #5f7bf0;
  border-radius: 18px;
  font-weight: 600;
  font-size: 34px;
}
</style>
